<!--  -->
<template>
  <div class="appeal">
    <div class="appeal-top card">
      <p class="top-p">申请维权</p>
      <div class="appeal-color">
        <ul>
          <li>
            <span>订单号：</span>
            <p>{{appeaList.order_no}}</p>
          </li>
          <li>
            <span>商品编号：</span>
            <p>{{appeaList.goods_id}}</p>
          </li>
          <li>
            <span>订单状态：</span>
            <p>{{appeaList.status_name}}</p>
          </li>
          <li>
            <span>下单时间：</span>
            <p>{{appeaList.created_at}}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="appeal-center card">
      <p>维权流程</p>
      <div class="appeal-img">
        <div class="appeal-commn">
          <img src="../../assets/member/1.png" alt="" v-if="isfalg" />
          <img src="../../assets/member/3.png" alt="" v-else/>
          <!-- <img src="../../assets/appeal/wq.png" alt="" v-if="tofalg"/> -->
          <span class="commn-title">勾选维权原因</span>
          <div class="tooltip1 tooltip">
            <span
              >维权说明：请务必选择真实遇到的问题，随意勾选维权原因可能导致核实出错，从而导致维权失败。</span
            >
          </div>
        </div>
        <div class="appeal-images">
          <img src="../../assets/member/jt 1.png" alt=""/>
        </div>


        <div class="appeal-commn">
          <img src="../../assets/member/12.png" alt="" v-if="isfalg1"  />
          <img src="../../assets/member/13.png" alt="" v-if="flag1"/>
           <img src="../../assets/appeal/tx.png" alt="" v-if="tofalg1"/>
          <span class="commn-title">填写维权详情</span>
          <div class="tooltip2 tooltip">
            <span>
              维权说明：1.使用优惠券订单，申请切切核实为非租
              方问题，全额退款可退还券，否则不退还券。2。订
              单维权成功实际到账金额不可超过实付租金。
            </span>
          </div>
        </div>

        <div class="appeal-images">
          <img src="../../assets/member/jt 1.png" alt="" v-if="flag1"  />
          <img src="../../assets/member/jt 2.png" alt="" v-else />
        </div>
        

        <div class="appeal-commn">
          <img src="../../assets/member/14.png" alt="" v-if="isfalg2"  />
          <img src="../../assets/member/15.png" alt="" v-if="flag2"/>
           <img src="../../assets/appeal/jh.png" alt="" v-if="tofalg2"/>
          <span class="commn-title">静候维权结果</span>
          <div class="tooltip3 tooltip">
            <span> 维权说明：维权无误资金将于1个工作日内返还您的账户。 </span>
          </div>
        </div>

        <div class="appeal-images">
          <img src="../../assets/member/jt 1.png" alt="" v-if="flag2" />
          <img src="../../assets/member/jt 2.png" alt="" v-else/>
        </div>


        <div class="appeal-commn">
          <img src="../../assets/member/21.png" alt="" v-if="(detaiList.refund_status==3&&xb_result_reason.orderStatus>=5)||(detaiList.xb_status>=0&&xb_result_reason != null)" />
          <img src="../../assets/appeal/jeg.png" alt="" v-else/>
          <span class="commn-title">维权结束</span>
          <div class="tooltip4 tooltip">
            <span> 维权说明：维权无误资金将于1个工作日内返还您的账户。 </span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="appeaList.status ==2">
        <div class="appeal-contaner card" v-if="goPage == 0">
          <div class="contaner-top">
            <p>您可能遇到了这些问题</p>
            <span>查看判定规则</span>
          </div>
          <div v-if="appeaList.new_channel==1">
            <div
              class="contaner-center"
              v-for="(item, index) in questionList"
              :key="index"
            >
              <p>{{ item.rightsTypeTitle}}</p>
              <ul>
                <li
                  v-for="(subItem, index) in item.rightsProblemList"
                  :key="index"
                  @click="selectId(subItem.id)"
                >
                <el-radio v-model="radio" :label= subItem.id>{{ subItem.rightsProblemTitle ||item}}</el-radio>
                <!-- <label style="cursor: pointer;"><input type="checkbox" :checked="checked"/> {{ subItem.rightsProblemTitle }}</label> -->
                </li>
              </ul>
            </div>
          </div>
           <ul v-else>
              <li
                v-for="(subItem, index) in questionList"
                :key="index"
                @click="selectId(index)"
              >
              <el-radio v-model="radio" :label= index>{{subItem}}</el-radio>
              </li>
           </ul>
         
          <div class="contaner-bottom">
            <p>找不到想玩的游戏？</p>
            <el-button type="primary" plain round size="mini" @click="goPages(1)"
              >下一步<i class="el-icon-arrow-right"></i
            ></el-button>
          </div>
        </div>
        <div class="appeal-contaner appeal-contaner1 card" v-if="goPage == 1">
          <div class="contaner-top">
            <p>填写维权申请</p>
          </div>
          <div class="contaner1-from">
            <div class="from-input" v-if="false">
              <div class="input-img">
                <img src="../../assets/order/xh.png" alt="" />
                <span>申请退款金额：</span>
              </div>
              <el-input placeholder="请输入金额" clearable> </el-input>
            </div>
            <div class="from-input">
              <div class="textarea">
                <img src="../../assets/order/xh.png" alt="" />
                <span>填写详细情况：</span>
              </div>
              <el-input
                type="textarea"
                :rows="6"
                placeholder="描述越详细成功率越高哦~（200字以内）"
                maxlength="200"
                show-word-limit
                v-model.trim="value"
              >
              </el-input>
            </div>
          </div>
          <div class="contaner1-title">
            <span>上传图片：</span>
            <p>
              {{ imgBase64.length }}/5张，每张图片最大支持1M，支持jpg/gif/png格式
            </p>
          </div>
          <div class="image-view">
            <div class="addbox">
              <input type="file" @change="getImgBase()" :disabled="disabled"/>
              <div class="addbtn">
                <span>+</span>
                <p>点击添加图片</p>
              </div>
            </div>
            <div class="view">
              <div class="item" v-for="(item, index) in imgBase64" :key="index">
                <span class="cancel-btn" @click="delImg(index)">x</span>
                <img :src="item" />
              </div>
            </div>
          </div>
          <div class="contaner1-bottom">
            <el-button type="primary" plain round size="mini" @click="goPages(2)"
              >下一步<i class="el-icon-arrow-right"></i
            ></el-button>
          </div>
        </div>
  </div>
   <div class="appeal-contaner appeal-contaner2 card" v-if="appeaList.status == 4 || goPage == 2">
      <div class="contaner-top">
        <p>维权记录</p>
      </div>
      <div class="contanner2-box">
        <div class="box-title">
          <p>租房于{{detaiList.created_at}} 创建维权申请</p>
          <p>维权原因：{{detaiList.refund_reason_msg}}</p>
          <p>申请退款金额：¥{{detaiList.pay_amount}}</p>
          <p>维权说明：{{detaiList.refund_desc}}</p>
        </div>
        <div class="box-img">
        <img :src="item" alt="" v-for="(item, i) in detaiList.refund_img" :key="i">
        </div>
      </div>
      <div class="contanner2-center">
        <p>维权金额：¥{{detaiList.pay_amount}}</p>
        <el-button type="primary" icon="el-icon-plus" size="mini" round plain
          v-if="false">添加凭证</el-button
        >
      </div>
      <div class="contanner2-bottom">
        <div class="contanner2-span">
          维权记录
          <span>（创建时间：{{detaiList.created_at}}）</span>
        </div>
        <div>
          <span>维权状态：</span>
          <span  v-if="detaiList.refund_status==0">待申诉</span>
          <span  v-if="detaiList.refund_status==1">审核中</span>
          <span  v-if="detaiList.refund_status==2">处理中</span>
          <!-- <span  v-if="detaiList.refund_status==3&&xb_result_reason维权失败</span>.orderStatus==6"> -->
        </div>
      </div>
    </div>
    <div class="appeal-contaner appeal-contaner2 appeal-contaner3 card" v-if="appeaList.status >4">
      <div class="contaner-top">
        <p>维权记录</p>
      </div>
      <div class="contanner3-box">
        <div class="box3-title">
          <span>客服：2020-09-09 21:15</span>
          <span v-if="(xb_result_reason.orderStatus==5) || (detaiList.xb_status ==1&&xb_result_reason != null)">处理结果：维权成功，已退款</span>
          <span v-if="(xb_result_reason.orderStatus==6)||(detaiList.xb_status ==0&&xb_result_reason != null)">处理结果：维权失败</span>
          <span v-if="xb_result_reason.orderStatus==6">处理描述：{{xb_result_reason.dealDescription}}</span>
          <p>租房于 {{detaiList.created_at}} 创建维权申请</p>
          <p>维权原因：{{detaiList.refund_reason_msg}}</p>
          <p>申请退款金额：¥{{detaiList.pay_amount}}</p>
          <p>维权说明：{{detaiList.refund_desc}}</p>
          <div class="img">
            <img :src="item" alt="" v-for="(item, i) in detaiList.refund_img" :key="i">
          </div>
        </div>
        <div class="box-img" v-if="xb_result_reason.orderStatus==5">
          <img src="../../assets/appeal/ytk.png" alt="" />
        </div>
      </div>
      <div class="contanner2-bottom">
        <div class="contanner2-span">
          维权记录
          <span>（创建时间：{{detaiList.created_at}}）</span>
        </div>
        <div>
          <span>维权状态：</span>
          <span  v-if="xb_result_reason.orderStatus==5">维权成功</span>
          <span  v-if="xb_result_reason.orderStatus==6">维权失败</span>
          <span  v-if="appeaList.new_channel==2">{{appeaList.status_name}}</span>
        </div>
      </div>
    </div>

    <div class="appeal-bottom card">
      <p>订单信息</p>
      <div class="bottom-color">
        <div>商品信息</div>
        <div>
          <ul>
            <li>商品价格</li>
            <li>租赁时长(小时)</li>
            <li>实付金额</li>
            <li>订单状态</li>
            <li>订单操作</li>
          </ul>
        </div>
      </div>
      <div class="bottom-price">
        <div>
          <span>订单号：{{appeaList.order_no}}</span>
          <span class="price-span">创建时间：{{appeaList.created_at}}</span>
        </div>
        <div>
          <span>租赁时间：{{appeaList.created_at}} 至 {{appeaList.end_time}}</span>
        </div>
      </div>
      <div class="bottom-box">
        <div class="bottom-img">
          <img :src= goods_info.game_img alt="" />
          <div>
            <p>
              {{goods_info.goods_title||goods_info.pn}}
            </p>
            <span class="comColor"> {{goods_info.game_all_name}}</span>
          </div>
        </div>
        <div class="comColor">{{goods_info.hour_lease||goods_info.pmoney}}元/小时</div>
        <div class="comColor">{{appeaList.game_time}}小时</div>
        <div class="bottom-info comColor">
          <!-- <span>¥3.5</span> -->
          <span>租金¥{{appeaList.pay_amount}}</span>
          <span>押金¥{{goods_info.foregift}}{{goods_info.bzmoney}}</span>
          <span>错误赔付¥0.00</span>
        </div>
        <div class="bottom-order">{{appeaList.status_name}}</div>
        <div class="bottom-btn">
          <el-button size="mini" round type="primary" @click="$router.push({path: `/member/myorder/orderdetail/${appeaList.order_no}`})">订单详情</el-button>
          <div class="btn-txt comColor" v-if="false">举报</div>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  name: "appeal",
  data() {
    return {
  

      tofalg:true,
      tofalg1:true,
      tofalg2:true,
      //箭头
      flag1: false,
      flag2: false,
      isfalg: false,
      isfalg1: false,
      isfalg2: false,
      // 变化记录
      changeValue:0,

      imgList: [],
      value: '',
      radio: '1',
      questionList: [],
      appeaList: {},
      goods_info:{},
      // 选择原因
      goPage: 0,
      // 上传图片
      imgBase64: [],
      // 复选框
      checked:false,
      disabled: false,
    
      detail: {},

      // 维权的数据
      detaiList: {},
      xb_result_reason: {}
    };
  },
  created() {
    this.getDetailsList()
  },
  mounted() {
  },
  methods: {
    // 提交问题
    submit(){
      let order_no = this.$route.params.id
      var that = this;
      if(that.radio<1){
        that.$message.warning('请选择维权问题')
        return
      }
      if(that.value.length<2){
        that.$message.warning('请输入维权内容')
        return
      }
      if(that.imgList.length<1){
        that.$message.warning('请上传凭证')
        return
      }
      that.$post('api/order/refund',{reason_id:that.radio,desc:that.value,refund_img:that.imgList,order_no}).then(res => {
        if(res.code==0){
          that.$message.success('提交成功')
          that.getRefundDetail()
          that.goPage = 2
          that.isfalg1 = false
          that.flag1 = true
          that.isfalg2 = true
          that.tofalg2 = false
        }else{
          that.$message.error(res.message);
          that.goPage = 1
        }
      })
    },
    // 选择问题
     getQuestion(){
      this.$get('api/FAQ', {},).then(res => {
        if (res.code == 0) {
          this.questionList =  res.ret
          console.log(this.questionList,'123');
        }
      })
    },
    // 选择问题1
     getTST(){
         this.$get('api/TST', {},).then(res => {
        if (res.code == 0) {
          this.questionList =  res.ret.list
          console.log(this.questionList,'123');
        }
      })
     },

      selectId(id) {
        this.radio = id
      },
    // 维权信息
    getDetailsList () {
      let user_id = this.$store.state.user_id
      let order_no = this.$route.params.id
      this.$get('api/order/detail', { user_id, order_no}).then(res => {
        if(res.code == 0) {
          console.log(res,'der');
          this.appeaList = res.ret
          if(res.ret.new_channel==1){
            this.goods_info = res.ret.goods_info
          }else{
            this.goods_info = res.ret.goods_info.data.list[0]
          }
          if(res.ret.status > 3){
            this.getRefundDetail()
          }
          if(res.ret.status == 2) {
              this.isfalg = true
              if(res.ret.new_channel==1){
                this.getQuestion()
              }else{
                this.getTST()
              }
          }else if (res.ret.status == 4||(res.ret.status == 4&&res.ret.new_channel==2)) {
            this.flag1 = true
            this.isfalg2 = true
            this.tofalg = false
            this.tofalg1 = false
            this.tofalg2 = false
          }
        }
      })
    },
    // 下一步
    goPages(num) {
      this.isfalg = false
      this.isfalg1 = true
      this.tofalg1= false
      if(num == 1) {
        this.goPage = 1;
      }
      if(num == 2) {
        this.submit()
      }
        
    },

    //获取图片base64实现预览
    getImgBase() {
      let order_no = this.$route.params.id
      var _this = this;
      var event = event || window.event;
      var file = event.target.files[0];
      // 图片张数控制
       if (this.imgBase64.length >= 4) {
           this.disabled = true;
        }
      var reader = new FileReader();
      //转base64
      reader.onload = function (e) {
        _this.$post('api/refund/img', {order_no,content: e.target.result}).then(res => {
         if (res.code == 0) {
        _this.imgList.push(res.ret);
        }
      })
        _this.imgBase64.push(e.target.result);
      };
      reader.readAsDataURL(file);
      
    },
    //删除图片
    delImg(index) {
      this.imgBase64.splice(index, 1);
      this.inputShow = true;
    },


    // 维权信息
     getRefundDetail () {
        let order_no = this.$route.params.id
        this.$post('api/refund/record', {order_no}).then(res => {
          if (res.code == 0) {
            this.detaiList = res.ret
            this.xb_result_reason = res.ret.xb_result_reason
              if( (this.detaiList.refund_status==3&&this.xb_result_reason.orderStatus >=5)||(this.detaiList.xb_status==1)){
                this.flag1 = true
                this.flag2 = true
                this.tofalg = false
                this.tofalg1 = false
                this.tofalg2 = false
              }else{
              this.tofalg = false
            }
          }
        })
      },
  },
};
</script>

<style lang='scss' scoped >
.appeal {
  width: 968px;
  float: right;
  .appeal-top {
    padding: 30px 0 0 38px;
    .top-p {
      position: relative;
      font-size: 16px;
      font-weight: bold;
      color: #000000;
      padding-left: 15px;
      &::after {
        content: "";
        position: absolute;
        top: 4px;
        left: 0px;
        width: 4px;
        height: 16px;
        background: #3c7efe;
        border-radius: 2px;
      }
    }
    .appeal-color {
      width: 894px;
      height: 80px;
      background: #f9fafb;
      border-radius: 4px;
      margin: 39px 0 27px;
      display: flex;
      justify-content: center;
      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 95%;
        li {
          display: flex;
          align-items: center;
          font-size: 14px;
          span {
            color: #83899d;
          }
          p {
            color: #010101;
          }
        }
      }
    }
  }
  .appeal-center {
    width: 100%;
    height: 240px;
    padding: 28px 38px;
    position: relative;
    .tooltip1 {
      position: absolute;
      bottom: 10px;
      width: 300px;
      left: 50px;
      span {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: -16px;
          left: 37px;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 7px solid #ebeef5;
          z-index: 10000;
        }
        &::before {
          content: "";
          position: absolute;
          top: -15px;
          left: 38px;
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 6px solid #fff;
          z-index: 10001;
        }
      }
    }
    .tooltip2 {
      position: absolute;
      bottom: 10px;
      left: 20%;
      width: 420px;
      span {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: -16px;
          left: 41%;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 7px solid #ebeef5;
          z-index: 10000;
        }
        &::before {
          content: "";
          position: absolute;
          top: -15px;
          left: 41%;
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 6px solid #fff;
          z-index: 10001;
        }
      }
    }
    .tooltip3 {
      position: absolute;
      bottom: 26px;
      left: 50%;
      span {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: -16px;
          left: 41%;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 7px solid #ebeef5;
          z-index: 10000;
        }
        &::before {
          content: "";
          position: absolute;
          top: -15px;
          left: 41%;
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 6px solid #fff;
          z-index: 10001;
        }
      }
    }
    .tooltip4 {
      position: absolute;
      bottom: 26px;
      left: 65%;
      span {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: -16px;
          left: 75%;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 7px solid #ebeef5;
          z-index: 10000;
        }
        &::before {
          content: "";
          position: absolute;
          top: -15px;
          left: 75%;
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 6px solid #fff;
          z-index: 10001;
        }
      }
    }
    p {
      font-size: 16px;
      font-weight: bold;
      color: #000000;
    }
    .appeal-img {
      display: flex;
      justify-content: space-between;
      padding: 30px;

      .appeal-commn {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        &:hover .tooltip1 {
          display: block;
        }
        &:hover .tooltip2 {
          display: block;
        }
        &:hover .tooltip3 {
          display: block;
        }
        &:hover .tooltip4 {
          display: block;
        }

        .commn-title {
          font-size: 14px;
          color: #a1a6b7;
          margin-top: 20px;
        }
        .commAcive {
          color: #3c7efe;
        }
      }
      .appeal-images {
        height: 100%;
        img {
          margin-top: 20px;
        }
      }
    }
  }
  .appeal-contaner {
    padding: 28px 38px 35px;
    .contaner-top {
      display: flex;
      align-items: flex-end;
      margin-bottom: 30px;
      p {
        font-size: 16px;
        color: #000000;
        margin-right: 10px;
        font-weight: bold;
      }
      span {
        font-size: 12px;
        color: #426cbd;
        cursor: pointer;
      }
    }
    .contaner-center {
      margin-top: 25px;
      p {
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #83899d;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          width: 25%;
          display: flex;
          align-items: center;
          height: 35px;
          text-align: center;
          font-size: 14px;
          color: #83899d;
          padding-left: 8px;
          
        }
      }
    }
    .contaner-bottom {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 30px 50px 0 0;
      p {
        font-size: 13px;
        color: #b4bdd6;
      }
    }
  }
  .appeal-contaner1 {
    height: 650px;
    .contaner1-from {
      .from-input {
        display: flex;
        margin-bottom: 30px;
        .textarea {
          display: flex;
          img {
            width: 6px;
            height: 6px;
            margin: 5px 5px 0 0;
          }
        }
        .input-img {
          display: flex;
          align-items: center;
          img {
            width: 6px;
            height: 6px;
            margin-right: 5px;
          }
        }
        .el-input {
          width: 30%;
        }
        .el-textarea {
          width: 89%;
        }
      }
    }
    .contaner1-title {
      display: flex;
      align-items: center;
      font-size: 13px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #a2a6b8;
      span {
        width: 96px;
        margin-right: 10px;
        text-align: right;
      }
    }
    .image-view {
      display: flex;
      align-items: center;
      margin: 30px auto 38px;
      width: 86%;
      .addbox {
        width: 114px;
        height: 114px;
        background: #f9fafb;
        border: 1px solid #e1e4eb;
        border-radius: 12px;
        position: relative;
        margin-right: 13px;
        input {
          position: absolute;
          left: 0;
          height: 114px;
          width: 114px;
          opacity: 0;
        }
        .addbtn {
          width: 114px;
          height: 114px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          span {
            font-size: 23px;
            color: #c2c5ce;
          }
          p {
            font-size: 13px;
            color: #848a9e;
          }
        }
      }
      .view {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        width: 83%;
        .item {
          width: 114px;
          height: 114px;
          border-radius: 12px;
          position: relative;
          cursor: pointer;
          margin-right: 13px;
          &:hover span {
            display: block;
          }
          img {
            width: 100%;
            height: 100%;
            border-radius: 12px;
          }
          span {
            position: absolute;
            right: 2px;
            top: -10px;
            font-size: 23px;
            color: #848a9e;
            display: none;
          }
        }
      }
    }
    .contaner1-bottom {
      padding-left: 64px;
    }
  }
  .appeal-contaner2 {
    .contanner2-box {
      width: 893px;
      height: 300px;
      background: #ffffff;
      border: 1px solid #e1e4eb;
      border-radius: 4px;
      padding: 24px;
      .box-title {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ff9f00;
        p {
          height: 24px;
        }
      }
      .box-img {
        margin-top: 27px;

        img {
          width: 120px;
          height: 120px;
          border-radius: 12px;
        }
      }
    }
    .contanner2-center {
      width: 100%;
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      .el-button {
        width: 10%;
        margin-top: 20px;
      }
    }
    .contanner2-bottom {
      width: 894px;
      height: 40px;
      background: #fafafb;
      border: 1px solid #e1e4eb;
      border-radius: 4px;
      display: flex;
      align-items: center;
      font-size: 13px;
      color: #010101;
      padding-left: 18px;
      margin-top: 20px;
      .contanner2-span {
        margin-right: 38px;
      }
      span {
        color: #848a9e;
      }
    }
  }
  .appeal-contaner3 {
    .contanner3-box {
      display: flex;
      justify-content: space-between;
      background: #ffffff;
      border: 1px solid #e1e4eb;
      border-radius: 4px;
      padding: 24px;
      .box3-title {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        span {
          color: #fc3838;
          height: 24px;
        }
        p {
          color: #ff9f00;
          height: 24px;
        }
        .img {
          width: 100%;
          height: 120px;
          margin-top: 27px;
          img {
            width: 120px;
            height: 100%;
            margin-right: 15px;
            border-radius: 12px;
          }
        }
      }
    }
  }
  .appeal-bottom {
    padding: 28px 38px 39px;
    p {
      font-size: 16px;
      color: #000000;
      height: 79px;
      line-height: 65px;
      font-weight: bold;
    }
    .bottom-color {
      width: 894px;
      height: 42px;
      background: #e7e9ec;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #83899d;
      font-size: 12px;
      padding: 0 20px;
      margin-bottom: 10px;
      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 500px;
      }
    }
    .bottom-price {
      width: 894px;
      height: 38px;
      background: #f6f7fa;
      border-radius: 4px 4px 0px 0px;
      font-size: 12px;
      color: #83899d;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      .price-span {
        margin-left: 50px;
      }
    }
    .bottom-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      width: 894px;
      border: 1px solid #f6f7fa;
      .bottom-img {
        display: flex;
        align-items: center;
        width: 310px;
        justify-content: space-between;
        img {
          width: 80px;
          height: 80px;
          border-radius: 12px;
        }
        p {
          width: 210px;
          height: 60px;
          font-size: 14px;
          font-weight: bold;
          color: #000000;
          line-height: 30px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      .bottom-info {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }
      .bottom-order {
        font-size: 13px;
        color: #000000;
      }
      .bottom-btn {
        text-align: center;
        .btn-txt {
          margin-top: 10px;
          cursor: pointer;
        }
      }
    }
  }
}

//
.card {
  width: 100%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ebeef5;
  background-color: #fff;
  transition: 0.3s;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 10px;
}
.tooltip {
  padding: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ebeef5;
  background-color: #fff;
  transition: 0.3s;
  border-radius: 12px;
  font-size: 12px;
  color: #83899d;
  display: none;
}
//
.comColor {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #83899d;
}
// 复选框
input[type="checkbox"] {
  width: 12px;
  height: 12px;
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  outline: 0 !important;
  cursor: pointer;
  position: relative;
  
}
input[type="checkbox"]:before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background: url(../../assets/appeal/wxz.png) no-repeat center;
  background-size: 100%;
  box-sizing: border-box;
  position: absolute;
}
input[type="checkbox"]:checked:before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  box-sizing: border-box;
  background: url(../../assets/appeal/yxz.png) no-repeat center;
  background-size: 100%;
  position: absolute;
}
</style>